import Assessment from "../../components/Dashboard/Assessment";
import { CardList } from "../../components/Dashboard/CardList";
import { ContactUs } from "../../components/Dashboard/ContactUs";
import Footer from "../../components/Footer";
import { Header } from "../../components/Header";
import HighlightedInfoBanner from "../../components/Dashboard/HighlightedInfoBanner";
import { OurLegacy } from "../../components/Dashboard/OurLegacy";
import Partnerships from "../../components/Dashboard/Partnerships";
import "./Dashboard.scss";

const Dashboard = () => {
  return (
    <html style={{
      minWidth: "26em",
      overflow: "hidden"
    }}>
      <div>
        <Header />
        <OurLegacy />
        <HighlightedInfoBanner />
        <CardList />
        <Partnerships />
        <Assessment />
        <ContactUs />
        <Footer />
      </div>
    </html>
  )
}

export default Dashboard;