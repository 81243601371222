import './Header.scss';
import './Header.mobile.scss';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export const Header = ({ reduced }: { reduced?: true }) => {
  const [burgerVisibility, setBurgerVisibility] = useState(false);

  return (
    <header id='header-top-page'>
      <div className='container'>

        {
          !reduced &&
          <div className={`menu-burger ${burgerVisibility ? 'visible' : ''}`}>
            <button>
              <img src={`/assets/images/menu-burger-icon.png`}
                onClick={
                  () => setBurgerVisibility(!burgerVisibility)
                }
              />
            </button>

            <div className='burger-nav'>
              <button>
                <img src={`/assets/images/close-icon.png`}
                  onClick={
                    () => setBurgerVisibility(!burgerVisibility)
                  }
                />
              </button>
              <ul>
                <li>
                  <a onClick={() => alert('Em breve!')}>Loja</a>
                </li>
                <li onClick={() => setBurgerVisibility(!burgerVisibility)}><a href="#ourLegacy">Sobre nós</a></li>
                <li onClick={() => setBurgerVisibility(!burgerVisibility)}><a href="#solutions">Soluções</a></li>
                <li onClick={() => setBurgerVisibility(!burgerVisibility)}><a href="#contact">Contato</a></li>
              </ul>
            </div>
          </div>
        }

        <Link to={'/'}>
          <img src="/assets/images/principal-logo.png" className="principal-logo" />
        </Link>
        <button className='client-area'><a href="https://intranet.krlservices.com.br/user/login" target='_blank'>Sou Cliente</a></button>
      </div>

      {
        !reduced &&
        <>
          <nav>
            <ul>
              <li onClick={() => alert('Em breve!')}>Loja</li>
              <li><a href="#ourLegacy">Sobre nós</a></li>
              <li><a href="#solutions">Soluções</a></li>
              <li><a href="#contact">Contato</a></li>
            </ul>
          </nav>
          <div className='header-banner reduced'>
            <div className='header-banner-content'>
              <img src='/assets/images/header/header-banner-text.png' />
              <a href="#solutions">
                <button>CONHEÇA</button>
              </a>
            </div>
          </div>
        </>
      }

    </header>
  )
}
