import "./HighlightedInfoBanner.scss";
import "./HighlightedInfoBanner.mobile.scss";

const HighlightedInfoBanner = () => {
  return(
    <div className="highlighted-container">
        <p>
          Especialistas em Abertura de Seguradoras, Relacionamento com a SUSEP, Desenvolvimento de Startups e Expansão de Novos Negócios.
        </p>
    </div>
  )
}

export default HighlightedInfoBanner;