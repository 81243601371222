import { Header } from "../../components/Header";
import WhatsLGPD from "../../components/LGPD/WhatsLGPD";

import "./LGPD.mobile.scss";
import "./LGPD.scss";

import Definitions from "../../components/LGPD/Definitions";
import Carosuel from "../../components/LGPD/Carosuel";
import Footer from "../../components/Footer";

const LGPD = () => {

  return (
    <html style={{
      minWidth: "26em",
      overflow: "hidden"
    }}>
      <Header
        reduced={true}
      />

      <section className="lgpd-header-banner">
        <h1>Privacidade e Proteção de <br /> Dados Pessoais</h1>
        <p>Saiba o que a LGPD significa para sua segurança</p>
        <a href="#practical-guide"><button>EXPLORE NOSSO GUIA PRÁTICO</button></a>
      </section>

      <WhatsLGPD />

      <Definitions />

      <div className="paragraphs">
        <div className="paragraph">
          <h1>O que a LGPD regulamenta?</h1>
          <p>A forma como deve ser o tratamento dos dados pessoais de qualquer pessoa física, que se relacione ou venha a se relacionar com o Controlador, isso inclui os dados de parceiros, prestadores de serviços, funcionários, candidatos e clientes.</p>
          <p>Respeitando a privacidade do titular envolvido, considerando que as ações sejam realizadas de forma clara, transparente e de fácil acesso. Destacamos alguns pontos importantes:</p>
          <ul>
            <li>Como seus dados pessoais serão tratados;</li>
            <li>Qual é a finalidade de utilização desses dados;</li>
            <li>Quais são as medidas aplicadas para garantir a segurança dos dados pessoais;</li>
            <li>Com quem seus dados são compartilhados;</li>
            <li>Quais são os direitos do titular que o titular tem sobre os seus dados pessoais.</li>
          </ul>
          <p>Portanto, qualquer ação de tratamento que envolva dados pessoais, deverá seguir as normas presentes pela LGPD e garantir os direitos aos titulares.</p>
        </div>

        <div className="paragraph">
          <h1>Como coletamos os seus dados pessoais:</h1>
          <p>Para que possamos fornecer os nossos serviços, o usuário deve inserir informações pessoais em nosso site, como: nome, telefone, e-mail entre outros e consentir com o tratamento.</p>
        </div>

        <div className="paragraph">
          <h1>Como os seus dados pessoais são utilizados:</h1>
          <p>Seus dados pessoais são utilizados para viabilizar nosso relacionamento.</p>
          <p>Como finalidade para a utilização dos dados pessoais temos:</p>
          <ul>
            <li>Sua identificação em nosso site;</li>
            <li>Conservar sua visita logada;</li>
            <li>Melhorar e personalizar sua experiência;</li>
            <li>Atender e cumprir as obrigações contratuais;</li>
            <li>Aprimorar nossa relação de parceria comercial e demais informações relevantes para a continuidade do nosso relacionamento.</li>
          </ul>
        </div>

        <div className="paragraph">
          <h1>Obrigação Legal e exercício regular de direitos:</h1>
          <p>Caso seja necessário o armazenamento por tempo superior, será realizado apenas para cumprimento de eventual obrigação legal e financeira por parte da KRL Services.</p>
          <p>Portanto, seus dados pessoais poderão ser utilizados para o atendimento de obrigações previstas em lei, podendo ser utilizados para resguardar nos nossos direitos e desempenho em processos judiciais, administrativos e arbitrais.</p>
          <p>Destacamos que iremos informá-lo (a) caso haja necessidade de solicitar novos dados pessoais e para qual finalidade será utilizado.</p>
        </div>

        <div className="paragraph">
          <h1>Como mantemos os seus dados pessoais seguros:</h1>
          <p>Respeitamos e nos preocupamos com a segurança dos seus dados pessoais, por isso, nos comprometemos a aplicar as medidas técnicas e organizacionais aptas a proteger os seus dados de acessos não autorizados e de situações de destruição, perda, alteração, comunicação ou difusão de tais dados, considerando as exigências previstas na LGPD e limites legais, mantendo nosso Programa de Governança e Privacidade atualizado e a confidencialidade.</p>
        </div>

        <div className="paragraph">
          <h1>Por quanto tempo armazenamos os seus dados pessoais:</h1>
          <p>Ressaltamos que, uma vez atingida a finalidade pretendida com o tratamento dos seus dados pessoais, estes serão eliminados através de medidas técnicas seguras viáveis e disponíveis no momento, exceto na hipótese de necessidade de cumprimento de obrigação legal ou regulatória.</p>
          <p>Ainda, não utilizamos seus dados pessoais para outros fins.</p>
        </div>

        <div className="paragraph">
          <h1>Quais são os seus direitos como titular de dados pessoais?</h1>
          <p>O titular de dados pessoais possui os seguintes direitos, conferidos pela Lei Geral de Proteção de Dados Pessoais (LGPD):</p>
          <ul>
            <li>Confirmação e acesso;</li>
            <li>Retificação;</li>
            <li>Limitação do tratamento dos dados;</li>
            <li>Oposição;</li>
            <li>Portabilidade dos dados;</li>
            <li>Não ser submetido a decisões automatizadas.</li>
          </ul>
        </div>

        <div className="paragraph">
          <h1>Encarregado de dados pessoais da KRL Services:</h1>
          <p>
            <strong>
              DPO – Oficial de Proteção de Dados <br />
              contato@krlservices.com.br
            </strong>
          </p>
          <p>A responsável por atuar como canal de comunicação entre o controlador, os titulares dos dados e a Autoridade Nacional de Proteção de Dados é a Encarregada contato@krlservices.com.br.</p>
        </div>

      </div>

      <Carosuel />

      <Footer
        reduced={true}
      />
    </ html>
  )
}

export default LGPD;