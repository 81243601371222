import { ValueCardProps } from "./ValueCardTypes";
import "./ValueCard.scss";

const ValueCard = (props:ValueCardProps) => {
  return(
    <div className="value-card">
        <img src={props.icon} />
        <div className="card-content">
            <h1>{props.title}</h1>
            <p>{props.content}</p>
        </div>
    </div>
  )
}

export default ValueCard;