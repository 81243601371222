import { createBrowserRouter } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import LGPD from "../pages/LGPD";

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Dashboard />,
  },
  {
    path: '/politica-de-privacidade',
    element: <LGPD />,
  }
]); 