import { useState } from "react";
import "./Carosuel.scss";
import "./Carosuel.mobile.scss";

const Carosuel = () => {

    const baseImagesUrl = '/assets/images/lgpd/';

    const images = [
        'slides-1.png',
        'slides-2.png',
        'slides-3.png',
        'slides-4.png',
        'slides-5.png',
        'slides-6.png',
        'slides-7.png',
        'slides-8.png',
        'slides-9.png',
        'slides-10.png',
        'slides-11.png',
    ];

    const [imagesIndex, setImagesIndex] = useState(0);

    return (
        <div className="carousel" id="practical-guide">
            <img src={`${baseImagesUrl}${images[imagesIndex]}`} />
            <div className="carousel-controls">
                <button onClick={() => setImagesIndex((prevIndex) => prevIndex > 0 ? prevIndex - 1 : images.length - 1)}>
                    <img src="/assets/images/left-arrow.png" />
                </button>
                <p>{imagesIndex + 1}/{images.length}</p>
                <button onClick={() => setImagesIndex((prevIndex) => (prevIndex + 1) % images.length)}>
                    <img src="/assets/images/right-arrow.png" />
                </button>
            </div>
        </div>
    )
}

export default Carosuel;