import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import "./Definitions.scss";
import "./Definitions.mobile.scss";

const Definitions = () => {
    return (
        <>
            <h1 className='definitions-title'>
                Seguem algumas definições para facilitar sua leitura:
            </h1>
            <div className='definitions-container'>
                <Tabs
                    defaultActiveKey="ANPD"
                    id="justify-tab-example"
                    className="tabs-container custom-tabs"
                    justify
                >

                    <Tab eventKey="ANPD" title="ANPD" className='tab'>
                        Autoridade Nacional de Proteção de Dados. Órgão da administração pública responsável por zelar, implementar, regulamentar e fiscalizar o cumprimento da Lei Geral de Proteção de Dados Pessoais em todo o território nacional.
                    </Tab>
                    <Tab eventKey="Bases Legais" title="Bases Legais" className='tab'>
                        Fundamentação legal que torna legítimo o tratamento de dados pessoais e dados pessoais sensíveis, para uma determinada finalidade. A LGPD apresenta 10 bases legais, entre elas, o consentimento expresso.
                    </Tab>
                    <Tab eventKey="Compartilhamento de Dados" title="Compartilhamento de Dados" className='tab'>
                        Prevenir que a informação seja revelada ou posta à disposição de indivíduos, entidades ou processos não autorizados.
                    </Tab>
                    <Tab eventKey="Consentimento" title="Consentimento" className='tab'>
                        Comunicação, difusão, transferência internacional, interconexão de dados pessoais ou tratamento compartilhado de bancos de dados pessoais por órgãos e entidades públicos no cumprimento de suas competências legais, ou entre esses e entes privados, reciprocamente, com autorização específica, para uma ou mais modalidades de tratamento permitidas por esses entes públicos, ou entre entes privados.
                    </Tab>
                    <Tab eventKey="Controlador" title="Controlador" className='tab'>
                        Manifestação livre, informada e inequívoca pela qual o titular concorda com o tratamento de seus dados pessoais para uma finalidade determinada.
                    </Tab>
                    <Tab eventKey="Dado Pessoal" title="Dado Pessoal" className='tab'>
                        Pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento de dados pessoais.
                    </Tab>
                    <Tab eventKey="Definições" title="Definições" className='tab'>
                        Informação relacionada a pessoa natural identificada ou identificável (“titular de dados”). Uma pessoa natural identificável é aquela que pode ser identificada, direta ou indiretamente, através do uso informações combinadas com dados identificadores, como número de identificação, dados de localização, entre outros.
                    </Tab>
                </Tabs>
                <Tabs
                    defaultActiveKey="Dado Pessoal Sensível"
                    id="justify-tab-example"
                    className="tabs-container custom-tabs"
                    justify
                >
                    <Tab eventKey="Dado Pessoal Sensível" title="Dado Pessoal Sensível" className='tab'>
                        Dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural.
                    </Tab>
                    <Tab eventKey="Eliminação" title="Eliminação" className='tab'>
                        Exclusão de dado ou de conjunto de dados armazenados em banco de dados, independentemente do procedimento empregado.
                    </Tab>
                    <Tab eventKey="Encarregado ou DPO (Data Protection Officer)" title="Encarregado ou DPO (Data Protection Officer)" className='tab'>
                        Pessoa indicada pelo controlador e operador para atuar como canal de comunicação entre o controlador, os Titulares dos dados e a Autoridade Nacional de Proteção de Dados (ANPD).
                    </Tab>
                    <Tab eventKey="Operador" title="Operador" className='tab'>
                        Pessoa natural ou jurídica, de direito público ou privado, que realiza o tratamento de dados pessoais em nome do controlador.
                    </Tab>
                    <Tab eventKey="Titular" title="Titular" className='tab'>
                        Pessoa natural a quem se referem os dados pessoais que são objeto de tratamento.
                    </Tab>
                    <Tab eventKey="Tratamento" title="Tratamento" className='tab'>
                        Toda operação realizada com dados pessoais, seja por meios eletrônicos ou manuais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.
                    </Tab>
                </Tabs>
            </ div>
        </>
    )
}

export default Definitions;