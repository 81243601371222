import "./OurLegacyImage.scss";
import "./OurLegacyImage.mobile.scss";

const OurLegacyImage = () => {
  return(
    <div className="ourLegacy-card">
        <div className="achievements">
            <p>
                <span className="color-text-orange">+10</span>
                Seguradoras <br/> Abertas
            </p>
            <p>
                <span className="color-text-orange">+100</span>
                Clientes Satisfeitos
            </p>
            <p>
                <span className="color-text-orange">+50</span>
                Especialistas
            </p>
        </div>
        <img src="/assets/images/ourLegacy/banner-content-image.png" alt="Fundo Padrão KRL" />
    </div>
  )
}

export default OurLegacyImage;