import "./OurLegacy.scss";
import "./OurLegacy.mobile.scss";

import { OurLegacyContent } from "./OurLegacyContent";
import OurLegacyImage from "./OurLegacyImage";

export const OurLegacy = () => {
  return(
    <div className="our-legacy-container" id="ourLegacy">
        <div className="our-legacy-title">
          <span>Quem Somos</span>
          <p>Experiência e inovação que <br/> <span>impulsionam resultados</span></p>
        </div>

        <div className="our-legacy-content-container">
          <OurLegacyContent />
          <OurLegacyImage />
        </div>
    </div>
  )
}