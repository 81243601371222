import "./WhatsLGPD.scss";
import "./WhatsLGPD.mobile.scss";

const WhatsLGPD = () => {
    return (
        <section className="whats-lgpd-container">
            <img src="/assets/images/lgpd/whats-lgpd-card.png" className="whats-lgpd-card" />
            <div className="container">
                <h1>
                    Explicando a LGPD 
                    <span>E SEU OBJETIVO</span>
                </h1>
                <p>
                    <strong>LGPD</strong> significa: Lei Geral de Proteção de Dados Pessoais, se trata da Lei Federal nº 13.709 de 14 de agosto de 2018, que visa proteger os direitos fundamentais de liberdade e privacidade de qualquer cidadão, denominado como titular, que esteja no Brasil no momento do tratamento, seja ela: coleta, compartilhamento, acesso, transferência e demais operações realizadas.
                </p>
                <p>
                    Essa lei representa um marco regulatório sobre o tratamento de dados pessoais, estabelecendo regras e resguardando os direitos dos titulares e a forma do tratamento.
                </p>
            </div>
        </section>
    )
}

export default WhatsLGPD;