import { Card } from "./Card"
import  './CardList.scss';
import './CardList.mobile.scss';

export const CardList = () => {
  return (
    <>
      <div className="card-list-title" id="solutions">
        <span className="line-title"></span>
        <h1><span className="color-text-black">Nossas soluções para</span><br /> seu sucesso</h1>
        <span className="line-title"></span>
      </div>
      <div className="card-list">
        <Card 
          title="Governança" 
          description="Direção e Monitoramento" 
          textContent="Processo pelo qual a empresa é dirigida e monitorada, envolvendo os relacionamentos entre sócios, conselho de administração, diretoria, colaboradores e órgãos reguladores."
          imageUrl="assets/images/cardList/governance-background.png" 
        />
        <Card 
          title="Operacional" 
          description="Crescimento e Relacionamento" 
          textContent="Promover o crescimento da empresa, com produtos, processos e metodologias inovadoras, protegendo os interesses e os ativos dos acionistas e o relacionamento com os clientes."
          imageUrl="assets/images/cardList/operational-background.png" 
        />
        <Card 
          title="Controladoria" 
          description="Solidez Financeira" 
          textContent="Assegurar a solidez financeira da empresa, acompanhando os resultados obtidos, monitorando e dando suporte às áreas financeira, contábil e operacional."
          imageUrl="assets/images/cardList/controllership-background.png" 
          className="expand-size"
        />
        <Card 
          title="Estratégico" 
          description="Gestão em linha com o objetivo" 
          textContent="Assegurar que a gestão do negócio seja executada em linha com o objetivo, metas e as diretrizes estabelecidas pelos acionistas e o regulatório."
          imageUrl="assets/images/cardList/strategic-background.png" 
        />
      </div>
    </>
  )
}