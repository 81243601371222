import { useState } from "react";

import "./OurLegacyContent.scss";
import "./OurLegacyContent.mobile.scss";

export const OurLegacyContent = () => {
  const legacyTexts = [
    [
      "Somos uma consultoria especializada em Seguros, dedicada a proporcionar uma abordagem personalizada aos nossos clientes. <br /> Nossa equipe atua em todo ecossistema operacional e estratégico, destacando: Governança, Operações, Planejamento Estratégico, Inovações Tecnológicas, Startups, Due Diligence e Desenvolvimento de Produtos. <br /> Aplicamos tecnologias inovadoras para assegurar soluções eficientes e flexíveis."
    ]
  ]

  const [indexText, setTextIndex] = useState<0 | 1>(0);

  return (
    <div className="our-legacy-content">
      <div>
        <div className="text">
          {
            legacyTexts[indexText][0]
              .split('<br />')
              .map((part, index) => (
                <>
                  <p key={index}>{part}</p>
                  <br />
                </>
              ))
          }
        </div>
      </div>
    </div>
  )
}