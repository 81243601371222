import "./Footer.scss";
import "./Footer.mobile.scss";

import { Link } from "react-router-dom";

const Footer = ({ reduced }: { reduced?: boolean }) => {
    return (
        <footer>
            {
                !reduced &&
                <div className="navigation-footer">
                    <ul>
                        <li onClick={() => alert('Em breve!')}>LOJA</li>
                        <li><a href="#ourLegacy">Sobre nós</a></li>
                        <li><a href="#solutions">Soluções</a></li>
                        <li><Link to={'/politica-de-privacidade'} target="_blank" rel="noopener noreferrer">LGPD</Link></li>
                    </ul>
                </div>
            }

            <div className="contact-infos">
                <a href="https://wa.me/5511983592385" target="_blank"><img src="/assets/images/footer/whatsapp-icon.png" /></a>
                <a href="#header-top-page"><img src="/assets/images/footer/home-icon.png" /></a>
                <a href="https://www.instagram.com/krl_services/" target="_blank"><img src="/assets/images/footer/instagram-icon.png" /></a>
                <a href="https://br.linkedin.com/company/krl-consultoria-e-assessoria-de-neg%C3%B3cios" target="_blank"><img src="/assets/images/footer/linkedin-icon.png" /></a>
                <a href="mailto:contato@krlservices.com.br"><img src="/assets/images/footer/email-icon.png" /></a>
            </div>

            {
                !reduced &&
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14621.162084144402!2d-46.734911!3d-23.6297646!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce514e4c6ac93d%3A0x9440a8b9d6cac309!2sKRL%20Services!5e0!3m2!1spt-BR!2sbr!4v1728011498177!5m2!1spt-BR!2sbr" width={'100%'} height={300} loading="lazy" frameBorder={0}></iframe>
            }

            <div className="phrase-track">
                <img src="/assets/images/white-logo.png" />
                <p>
                    Time de especialistas apaixonados e experientes que abraçam desafios!
                </p>
            </div>
        </footer>
    )
}

export default Footer; 