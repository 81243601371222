import ValueCard from "./ValueCard";
import "./Assessment.scss";
import "./Assessment.mobile.scss";

const Assessment = () => {
  return (
    <div className="assessment-container">
      <div className="assessment-content">
        <h1 className="assessment-title">Nossas estratégias <br/> expandem os horizontes <br /> <span className="orange-text">do seu negócio</span></h1>
        <div className="our-values">
          <ValueCard
            icon="/assets/images/assessment/vision-icon.png"
            title="Visão"
            content="Compromisso com a excelência, entrega eficiente e melhoria contínua, agregando valor aos negócios de nossos clientes e parceiros."
          />

          <ValueCard
            icon="/assets/images/assessment/mission-icon.png"
            title="Missão"
            content="Oferecer tranquilidade aos nossos clientes com soluções personalizadas e parcerias de longo prazo."
          />

          <ValueCard
            icon="/assets/images/assessment/values-icon.png"
            title="Valores"
            content="Determinação, inovação, segurança, integridade e valorização das pessoas."
          />
        </div>
      </div>
      <img 
        src="/assets/images/assessment/assessment-card-banner.png"
        alt="Banner Avaliação" 
        className="assessment-card-banner"
      />
    </div>
  )
}

export default Assessment;