import "./Card.scss";
import { CardProps } from "./CardTypes";

export const Card = (props:CardProps) => {
    return (
        <div className={`card ${props.className ? props.className : ''}`}>
            <div className="card-background-image" style={{ backgroundImage: `url(${props.imageUrl})`}}></div>
            <div className="card-label">
                <p>{props.title}</p>
                <p>{props.description}</p>
            </div>
            <div className="card-text">
                <p>{props.textContent}</p>
            </div>
        </div>
    )
}; 