import "./Partnerships.scss";
import "./Partnerships.mobile.scss";

const Partnerships = () => {
  return(
    <div className="partnerships-container">
        <img src="/assets/images/partnerships/logos.png" className="logos" />
        <img src="/assets/images/partnerships/dialog-box.png" className="assessment" />
    </div>
  )
}

export default Partnerships;