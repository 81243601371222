import { useState, useEffect } from "react";
import emailjs from 'emailjs-com';
import "./ContactUs.scss";
import "./ContactUs.mobile.scss";

export const ContactUs = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!name.trim() || !email.trim() || !message.trim()) {
            alert('Por favor, preencha todos os campos corretamente.');
            return;
        }

        const templateParams = {
            name,
            email,
            message,
        };

        emailjs.send('service_nek81it', 'template_jtfk9fe', templateParams, 'kBaX2pM6zfGk45Mzl')
            .then((response) => {
                console.log(response.status, response.text);
                setSuccess(true);  
                setName('');       
                setEmail('');
                setMessage('');
            })
            .catch((error) => {
                console.log('Erro ao enviar o e-mail:', error);
            });
    };

    useEffect(() => {
        if (success) {
            const timer = setTimeout(() => {
                setSuccess(false);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [success]);

    return(
        <div className="contact-us-container" id="contact">
            <h1>Entre em contato conosco!</h1>
            <div className="form">
                <form onSubmit={handleSubmit}>
                    <span>
                        <div className="input-container">
                            <label htmlFor="text">Nome: </label>
                            <input type="text" name="name" onChange={(e) => setName(e.target.value)} value={name} />
                        </div>
                        <div className="input-container">
                            <label htmlFor="email">E-mail: </label>
                            <input type="email" name="email" onChange={(e) => setEmail(e.target.value)} value={email} required />
                        </div>
                    </span>
                    
                    <div className="input-container">
                        <label className="input-container" htmlFor="message">Mensagem: </label>
                        <textarea name="message" onChange={(e) => setMessage(e.target.value)} value={message}></textarea>
                    </div>

                    <button type="submit">Enviar</button>
                </form>
                {success && <p>Email enviado com sucesso!</p>}
            </div>
        </div>
    );
};
